<template>
	<div
		ref="autocompleter"
		:class="[
			'absolute z-50 list-none cursor-pointer autocompleter min-w-global-search max-h-400 w-full lg:max-w-sm border-b lg:border border-gray-300 lg:rounded-lg md:shadow-md bg-white',
			!locationOnly ? 'top-24 lg:top-11' : 'top-[50px] lg:top-11',
			{'overflow-y-auto': !showEmptyStateList},
			isLoginPage ? 'pt-2 md:py-2' : 'py-2'
		]"
	>
		<SearchResultList
			v-if="hasSearchResults"
			:items="searchResults"
			:is-mobile="true"
			:list-type="GLOBAL_LIST"
			:is-search-input="true"
			:layout-type="FLEX_COL"
			:location-only="locationOnly"
			:enable-accessibility-scroll="!showEmptyStateList"
			@search-result-clicked="handleSearchResultClick"
		/>
		<SearchInputEmptyList v-if="showEmptyStateList" />
		<LoadingSpinner
			v-if="hasSearchTerm && isLoading"
			class="ml-2.5 w-8 h-8"
		/>
		<div
			v-if="hasSearchTerm && !isLoading && !hasSearchResults"
			class="italic cursor-default"
			:class="{'pb-3 md:pb-0': isLoginPage}"
		>
			No results found
		</div>
	</div>
</template>

<script async>
import { mapActions } from 'vuex'

import { FLEX_COL } from '@/constants/search/layoutTypes.js'
import { GLOBAL_LIST } from '@/constants/search/listTypes.js'

export default {
	components: {
		LoadingSpinner: () => import('@/components/multiUse/LoadingSpinner.vue'),
		SearchResultList: () => import('@/components/search/SearchResultList.vue'),
		SearchInputEmptyList: () => import('@/components/search/SearchInputEmptyList.vue')
	},
	props: {
		locationOnly: {
			type: Boolean,
			default: false
		},
		searchResults: {
			type: Array,
			required: true
		},
		searchTerm: {
			type: String,
			default: ''
		},
		isLoading: {
			type: Boolean,
			required: true
		}
	},
	emits: [ 'geo-location', 'search-result-clicked' ],
	data() {
		return {
			GLOBAL_LIST,
			FLEX_COL
		}
	},
	computed: {
		hasSearchResults() {
			return !!this.searchResults?.length
		},
		hasSearchTerm() {
			return !!this.searchTerm?.length
		},
		showEmptyStateList() {
			return !this.hasSearchResults && !this.hasSearchTerm && !this.locationOnly
		},
		isLoginPage() {
			return this.$route.name === 'login'
		}
	},
	methods: {
		async getLocation() {
			this.$emit('geo-location')
		},
		...mapActions('search', [ 'setHighlightedRoute' ]),
		handleSearchResultClick() {
			this.$emit('search-result-clicked')
		}
	}
}
</script>

<style scoped>
.max-h-400 {
	max-height: 400px;
}
.min-w-global-search {
	min-width: 18rem;
}
</style>
